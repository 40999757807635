import { useState } from 'react'
import { Container, Form, Row, Col, ButtonGroup, Table } from 'react-bootstrap'
import { FaCaretDown, FaArrowDown, FaArrowUp, FaTimes } from 'react-icons/fa'
import { MyPopover, LinkButton, TextInput, Checkbox, ThSort } from '../widgets'
import { toCurrency, toPercent } from '../../libs/utilLib'
import { orderBy, groupBy } from 'lodash'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { columnOptions, pieOptions } from '../../libs/chartLib'

export const HoldingsChart = ({ items, columns, onClose }) => {
    const prefix = 'HoldingsChart'
    const [state, setState] = useState({
        showTop: JSON.parse(localStorage.getItem(`${prefix}_showTop`)) || 20,
        height: JSON.parse(localStorage.getItem(`${prefix}_height`)) || 500,
        sortByValue: JSON.parse(localStorage.getItem(`${prefix}_sortByValue`)),
        showBar: JSON.parse(localStorage.getItem(`${prefix}_showBar`) || true),
        showPie: JSON.parse(localStorage.getItem(`${prefix}_showPie`) || true),
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'category',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'desc',
        categoryColumn: JSON.parse(localStorage.getItem(`${prefix}_categoryColumn`)) || 'symbol',
        seriesColumn: 'marketValue'
    })

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onChange = ({ target }) => {
        let { type, name, value } = target
        if (type === 'number') value = +value
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
        setState({ ...state, [name]: value })
    }

    const onSelect = (name, value) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
        setState({ ...state, [name]: value })
    }

    const onResize = (size) => {
        let { height } = state
        if (height === null || height === undefined) height = 400
        height = Math.max(height + size, 0)
        localStorage.setItem(`${prefix}_height`, JSON.stringify(height))
        setState({ ...state, height })
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'desc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const getColumnOptions = (items) => {
        let items1 = items.filter(x => x[state.seriesColumn] > 0)
        if (state.sortByValue) items1 = orderBy(items1, [state.seriesColumn], ['desc'])
        let items2 = items1.slice(0, state.showTop)
        let categories = items2.map(x => x[state.categoryColumn])
        let series = [
            { data: items2.map(x => Math.round(x[state.seriesColumn])) },
            // { name: 'Security', data: items2.map(x => Math.round(x.marketValue)) }
        ]
        if (items1.length > state.showTop) {
            categories.push('Others')
            series[0].data.push(items1.slice(state.showTop + 1).reduce((t, i) => t + Math.round(i[state.seriesColumn]), 0))
            // series[1].data.push(items1.slice(state.showTop + 1).reduce((t, i) => t + Math.round(i.marketValue), 0))
        }
        return { categories, series }
    }
    const getPieOptions = (items) => {
        let items1 = orderBy(items, [state.seriesColumn], ['desc']).filter(x => x[state.seriesColumn] > 0)
        let series = null
        if (items1.length > state.showTop) {
            series = [{ data: [...items1.slice(0, state.showTop).map(x => ({ name: x[state.categoryColumn], y: +x[state.seriesColumn] })), { name: 'Others', y: items1.slice(state.showTop + 1).reduce((t, i) => t + i[state.seriesColumn], 0) }] }]
        } else {
            series = [{ data: items1.map(x => ({ name: x[state.categoryColumn], y: +x[state.seriesColumn] })) }]
        }
        return { series }
    }

    if (!items) return null
    let grp = groupBy(items, state.categoryColumn)
    let items1 = Object.keys(grp).map(k => ({[state.categoryColumn]:k, [state.seriesColumn]: grp[k].reduce((t, i) => t += i[state.seriesColumn], 0)}))
    let paidTotal = items.reduce((t, i) => t += i.paid||0, 0)
    let marketTotal = items.reduce((t, i) => t += i.marketValue||0, 0)
    let items2 = Object.keys(grp).map(k => ({
        category:k, 
        paid: grp[k].reduce((t, i) => t += i.paid, 0), 
        marketValue: grp[k].reduce((t, i) => t += i.marketValue, 0), 
        totalChange: grp[k].reduce((t, i) => t += i.winAmount, 0),        
        dayChange: grp[k].reduce((t, i) => t += i.dayChange, 0)
    })).map(x => ({...x, paidPortion: x.paid/paidTotal, marketPortion: x.marketValue/marketTotal, totalPercent: x.totalChange/x.marketValue, dayPercent: x.dayChange/x.marketValue}))
    if (state.sortBy && state.sortDir) items2 = orderBy(items2, [state.sortBy], [state.sortDir])
    return (
        <div className="mt-3">
            <div>
                <div className="float-end">
                    <ButtonGroup>
                        <LinkButton variant="outline-secondary" icon={<FaArrowDown />} onClick={e => onResize(200)} className="" />
                        <LinkButton variant="outline-secondary" icon={<FaArrowUp />} onClick={e => onResize(-200)} className="" />
                    </ButtonGroup>
                    <LinkButton variant="outline-secondary" icon={<FaTimes />} onClick={onClose} className="pr-0 ms-2" />
                </div>
                <h5 className="d-inline-block me-2">Distribution charts</h5>
                <MyPopover button={<FaCaretDown />}>
                    <span className="me-2"><b>Items:</b></span>
                    First <TextInput type="number" name="showTop" value={state.showTop} onChange={onChange} className="d-inline-block me-3" inputStyle={{ width: 60, height: 26 }} />
                    <hr className="my-1" />
                    <b className="me-2">Chart:</b>
                    <Checkbox label="Bar" checked={state.showBar} onCheck={e => onToggle('showBar')} className="d-inline-block" />
                    <Checkbox label="Pie" checked={state.showPie} onCheck={e => onToggle('showPie')} className="d-inline-block" />
                    <hr className="my-1" />
                    <div className="bold mb-2">Group by:</div>
                    <Row className="clear-both">
                        {columns.filter(x => x.type === 'text').map((x, i) => <Col key={i} xs={6}>
                            <span onClick={e => onSelect('categoryColumn', x.id)}><Form.Check type="radio" checked={x.id === state.categoryColumn} label={x.name} readOnly /></span>
                        </Col>)}
                    </Row>
                    <hr className="my-1" />
                    <Checkbox label="Sorted" checked={state.sortByValue} onCheck={e => onToggle('sortByValue')} className="d-inline-block me-0 float-end" />
                    <div className="bold mb-2">Series:</div>
                    <Row className="clear-both">
                        {columns.filter(x => 'number,currency'.includes(x.type)).map((x, i) => <Col key={i} xs={6}>
                            <span onClick={e => onSelect('seriesColumn', x.id)}><Form.Check type="radio" checked={x.id === state.seriesColumn} label={x.name} readOnly /></span>
                        </Col>)}
                    </Row>
                </MyPopover>
                {/* <br className="d-sm-only" />
                <span className="f-sm">
                    Series:<MyPopover button={<LinkButton iconEnd={<FaCaretDown />} text={columns.find(x => x.id === state.seriesColumn)?.name} className="mt-n1 me-2" />}>
                        <div className="text-left">
                            {columns.filter(x => 'number,currency'.includes(x.type)).map((x, i) => <div key={i}>
                                <span onClick={e => onSelect('seriesColumn', x.id)}><Form.Check type="radio" checked={x.id === state.seriesColumn} label={x.name} readOnly /></span>
                            </div>)}
                        </div>
                    </MyPopover>
                    <Checkbox label="Sorted" checked={state.sortByValue} onCheck={e => onToggle('sortByValue')} className="d-inline-block me-2" />
                    <span className="me-2">Show first:</span>
                    <TextInput type="number" name="showTop" value={state.showTop} onChange={onChange} className="d-inline-block me-3" inputStyle={{ width: 60, height: 26 }} />
                    <Checkbox label="Bar" checked={state.showBar} onCheck={e => onToggle('showBar')} className="d-inline-block" />
                    <Checkbox label="Pie" checked={state.showPie} onCheck={e => onToggle('showPie')} className="d-inline-block" />
                </span> */}
            </div>

            <div>
                <Row>
                    {state.showBar && <Col md={state.showPie ? 8 : 12}>
                        <HighchartsReact highcharts={Highcharts} options={columnOptions({ ...getColumnOptions(items1), height: state.height })} />
                    </Col>}
                    {state.showPie && <Col md={state.showBar ? 4 : 12}>
                        <HighchartsReact highcharts={Highcharts} options={pieOptions({ ...getPieOptions(items1), height: state.height })} />
                    </Col>}
                </Row>
                <Container size="sm">
                    <Table size="sm" hover className="nowrap f-sm mb-0">
                        <thead>
                            <tr>
                                <ThSort text="Category" id="category" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} />
                                <ThSort text="Paid" id="paid" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Paid %" id="paidPortion" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Mkt value" id="marketValue" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Mkt %" id="marketPortion" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Total chg $" id="totalChange" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Total chg %" id="totalPercent" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Day chg $" id="dayChange" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                                <ThSort text="Day chg %" id="dayPercent" sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className="text-end"/>
                            </tr>
                        </thead>
                        <tbody>
                            {items2.map((x, i) => <tr key={i}>
                                <td>{x.category}</td>
                                <td className="text-end">{toCurrency(x.paid)}</td>
                                <td className="text-end">{toPercent(x.paidPortion, 1, 1)}</td>
                                <td className="text-end">{toCurrency(x.marketValue)}</td>
                                <td className="text-end">{toPercent(x.marketPortion, 1, 1)}</td>
                                <td className="text-end" style={{ color: x.totalChange < 0 ? 'red' : 'blue' }}>{toCurrency(x.totalChange)}</td>
                                <td className="text-end" style={{ color: x.totalChange < 0 ? 'red' : 'blue' }}>{toPercent(x.totalPercent, 2, 2)}</td>
                                <td className="text-end" style={{ color: x.dayChange < 0 ? 'red' : 'blue' }}>{toCurrency(x.dayChange)}</td>
                                <td className="text-end" style={{ color: x.dayChange < 0 ? 'red' : 'blue' }}>{toPercent(x.dayPercent, 2, 2)}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Container>
            </div>
        </div>
    )
}