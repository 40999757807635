import { useState, useEffect, useContext } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { apiDelete, apiPut } from '../../libs/apiLib'
import { groupBy } from 'lodash'
import { Checkbox, MyPopover, Unauthorized, LinkButton, ConfirmButton, SearchBox, Loading, Pin } from '../widgets'
import { QuotesView } from '../quote'
import { FaCaretDown, FaChevronUp, FaChevronDown, FaEdit, FaPlus, FaTimes, FaPaste } from 'react-icons/fa'
import { GlobalContext } from '../helpers'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { toDate } from '../../libs/utilLib'
import { Container, Row, Col } from 'react-bootstrap'
import { NewsView } from '../news'
import { Link } from 'react-router-dom'

const WatchlistsPage = () => {
  const prefix = 'WatchlistsPage'
  const { id: userId } = useParams()
  const { gState } = useContext(GlobalContext)
  const [state, setState] = useState({
    working: true,
    pinHide: JSON.parse(localStorage.getItem(`${prefix}_pinHide`)),
    hideUps: JSON.parse(localStorage.getItem(`${prefix}_hideUps`)),
    hideDowns: JSON.parse(localStorage.getItem(`${prefix}_hideDowns`)),
    // sidebarSize: JSON.parse(localStorage.getItem(`${prefix}_sidebarSize`)) || 'M',
    // showNews: JSON.parse(localStorage.getItem(`${prefix}_showNews`) || true),
    // showNotes: JSON.parse(localStorage.getItem(`${prefix}_showNotes`) || true),
    // watchlists: null,
    watchlistId: JSON.parse(localStorage.getItem(`${prefix}_watchlistId`)),
  })

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    // if (!gState.isAdmin() && gState.loginUser?._id !== userId) return
    // const { error, items: watchlists } = await apiGetList({ model: 'watchlist', filter: { userId }, sort: { name: 1 } })
    const watchlists = await gState.getWatchlists()
    // let watchlistId = state.watchlistId || watchlists[0]._id
    let watchlist = getWatchlist(null, watchlists, state.watchlistId)
    setState({ ...state, watchlistId: watchlist?._id, watchlist, working: false })
  }

  const onSearch = (searchText, searchHeader) => {
    let watchlist = getWatchlist(searchText)
    if (searchHeader) {
      watchlist.name = searchHeader
      watchlist.tickers = searchText.split(/\s+|,|=/).filter(x => x)
    }
    setState({ ...state, searchText, searchHeader, watchlist })
  }

  const getWatchlist = (searchText, watchlists, watchlistId) => {
    let watchlist = null
    if (searchText) {
      watchlist = { _id: 'search', name: state.searchHeader || 'Search', searchText: searchText !== searchText.toUpperCase() ? searchText : '^' + searchText.replace(/ /g, '$|^') + '$' }
    }
    else if (watchlistId === 'favor' && gState.loginUser) {
      watchlist = { _id: 'favor', name: 'My favorites', tickers: gState.loginUser.favTickers?.map(x => x.symbol) }
    } else {
      watchlist = watchlists.find(x => x._id === watchlistId)
      if (!watchlist && watchlists?.length > 0) watchlist = watchlists[0]
    }
    return watchlist
  }

  const onWatchlist = (watchlist) => {
    localStorage.setItem(`${prefix}_watchlistId`, JSON.stringify(watchlist._id))
    setState({ ...state, watchlistId: watchlist._id, watchlist })
  }

  const onDelete = async (watchlist) => {
    let result = await apiDelete('watchlist', watchlist._id)
    console.log(result)
    toast(`Account '${watchlist.name}' deleted successfully.`)
    if (watchlist._id === state.watchlistId) localStorage.removeItem(`${prefix}_watchlistId`)
    await fetchData()
  }

  const onRemoveSymbol = async (symbol) => {
    if (state.watchlist?._id === 'search') return
    if (state.watchlist?._id === 'favor') {
      gState.loginUser.favTickers = gState.loginUser.favTickers.filter(x => x.symbol !== symbol)
      gState.updateLoginUser(gState.loginUser)
    } else {
      let tickers = state.watchlist?.tickers
      if (tickers?.includes(symbol)) {
        tickers = tickers.filter(x => x !== symbol)
        let result = await apiPut('watchlist', state.watchlist._id, { tickers })
        console.log(result)
        fetchData()
      }
    }
  }

  const onStop = (e) => {
    e.stopPropagation()
  }

  // const onSet = (name, value) => {
  //   localStorage.setItem(`${prefix}_${name}`, JSON.stringify(value))
  //   setState({ ...state, [name]: value })
  // }

  const onToggle = (name) => {
    localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
    setState({ ...state, [name]: !state[name] })
  }

  const onFavor = () => {
    const watchlist = { _id: 'favor', name: 'My favorites', tickers: gState.loginUser?.favTickers?.map(x => x.symbol) }
    localStorage.setItem(`${prefix}_watchlistId`, JSON.stringify(watchlist._id))
    setState({ ...state, watchlistId: watchlist._id, watchlist })
  }

  const onHover = (item, hovering) => {
    gState.watchlists.forEach(x => x.isHovering = x === item)
    setState({...state})
  }

  const onPaste = async () => {
    // let txt = await window.navigator.clipboard.readText()
    let txt = state.clipboardText
    if (txt){
      const tickers = txt.split(/\s+|,|\t|\r?\n/)
      await apiPut('watchlist', state.watchlist._id, { tickers })
      window.location.reload(false)
    } else {
      toast.warn(`Nothing to paste`)
    }
  }

  const getClipboardText = async () => {
    const clipboardText = await window.navigator.clipboard.readText()
    console.log("clipboardText", clipboardText)
    setState({...state, clipboardText})
  }

  const dropdownMenu = () => {
    const grp = groupBy(gState.watchlists, x => x.category || '')
    // console.log(grp)
    const n = gState.loginUser?.favTickers?.length || 0
    return (
      <>
        <MyPopover button={<span className="link me-3 f-lg"><FaCaretDown /></span>} style={{ minWidth: 280 }}>
          <Pin pinned={state.pinHide} onClick={e => onToggle('pinHide')} className="my-2"/>
          <div className="mb-2">
              <span className='me-3'>Hide</span>
              <Checkbox label="Ups" checked={state.hideUps} onCheck={e => onToggle('hideUps')}/>
              <Checkbox label="Downs" checked={state.hideDowns} onCheck={e => onToggle('hideDowns')}/>
          </div>
          <hr className="my-1 clear-both" />
          <div><LinkButton text={<b>My favorites ({n})</b>} onClick={onFavor} className="ps-0" /></div>
          <hr className="my-1" />
          <div className="float-end"><LinkButton icon={<FaPlus />} text="Add" to={`/watchlist/add`} className="p-0" /></div>
          <div className="bold my-2">Watchlists:</div>
          {/* <hr className="my-1 clear-both" /> */}
          {Object.keys(grp).sort().map((k, j) => <div key={j} className="clear-both">
            {!!k && <div>
              <div className="float-end" onClick={onStop}>
                <Link onClick={e => onToggle(k)} className='ms-3'>{state[k] ? <FaChevronUp/> : <FaChevronDown/>}</Link>
              </div>
              <div className="blueviolet my-1">{k}</div>
            </div>}
            {(state[k] || !k) && grp[k].map((x, i) => <div key={i} className="clear-both" onMouseEnter={e => onHover(x, true)} onMouseLeave={e => onHover(x, false)}>
              {x.isHovering && <div className="float-end">
                <LinkButton icon={<FaEdit />} to={`/watchlist/${x._id}/edit`} className="pt-0 pe-1" />
                <ConfirmButton icon={<FaTimes />} variant="link" confirmText="Delete" onClick={e => onDelete(x)} className="pt-0 pe-0" />
              </div>}
              <div><Checkbox type="radio" label={x.name + ' (' + (x.tickers?.length || 0) + ')'} checked={state.watchlist === x} onCheck={e => onWatchlist(x)} title={`Created ${format(toDate(x.createdAt), 'yyyy-MM-dd')}`} /></div>
            </div>)}
          </div>)}
          {/* {gState.watchlists?.map((x, i) => <div key={i} className="clear-both">
            <div className="float-end">
              <LinkButton icon={<FaEdit />} to={`/watchlist/${x._id}/edit`} className="pt-0 pe-1 grey" />
              <ConfirmButton icon={<FaTimes />} variant="link" confirmText="Delete" onClick={e => onDelete(x)} className="pt-0 pe-0 grey" />
            </div>
            <div><Checkbox type="radio" label={x.name + ' (' + (x.tickers?.length || 0) + ')'} checked={state.watchlist === x} onCheck={e => onWatchlist(x)} title={`Created ${format(toDate(x.createdAt), 'yyyy-MM-dd')}`} /></div>
          </div>)} */}
        </MyPopover>
        <SearchBox searchText={state.searchText} onSearch={onSearch} searchOnEnter={true} placeholder='Search symbols...' />
        {state.pinHide && <span className="fw-normal f-sm mx-3">
              <span className='me-3'>Hide</span>
              <Checkbox label="Ups" checked={state.hideUps} onCheck={e => onToggle('hideUps')}/>
              <Checkbox label="Downs" checked={state.hideDowns} onCheck={e => onToggle('hideDowns')}/>
        </span>}
      </>
    )
  }

  const dropdownRight = () => {
    return (
      <>
        <hr className="my-1" />
        {/* <div><LinkButton text={`Paste symbols`} onClick={onPaste} className="ps-0" /></div> */}
        <ConfirmButton icon={<FaPaste />} variant="link" text={`Paste symbols`} msg={state.clipboardText} onClick={e => onPaste()} onStart={getClipboardText} className="ps-0" />
        <hr className="my-1" />
        <span className="me-3 bold">Sidebar size:</span>
        {'S,M,L'.split(',').map((x, i) => <span key={i}>
          <Checkbox type="radio" label={x} checked={gState.sidebarSize === x} onCheck={e => gState.onSet('sidebarSize', x)} />
        </span>)}
        <div><Checkbox label="Show news" checked={!!gState.showNews} onCheck={e => gState.onToggle('showNews')} /></div>
        <div><Checkbox label="Show notes" checked={!!gState.showNotes} onCheck={e => gState.onToggle('showNotes')} /></div>
        <hr className="my-1" />
        <div><Checkbox label="Hide topbar" checked={!!gState.hideTopbar} onCheck={e => gState.onToggle('hideTopbar')} /></div>
      </>
    )
  }

  if (!gState.isAdmin() && gState.loginUser?._id !== userId) return <Unauthorized />
  // if (state.working) return <Loading />
  // if (state.error) return <Error error={state.error} /> 
  document.title = "Watchlists - " + global.appName
  if (!gState.watchlists?.length) return <Loading/>
  // if (!state.watchlist) return null
  // const canRemoveSymbol = !'search,favor'.includes(state.watchlist._id)
  const sidebarSize = gState.sidebarSize === 'S' ? 2 : gState.sidebarSize === 'M' ? 3 : 4 
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={!gState.showNews ? 12 : (12 - sidebarSize)}>
          <QuotesView key={state.watchlist?._id} watchlist={state.watchlist} hideUps={state.hideUps} hideDowns={state.hideDowns} dropdownMenu={dropdownMenu()} onRemoveSymbol={onRemoveSymbol} dropdownRight={dropdownRight()}/>
        </Col>
        {(gState.showNews || gState.showNotes) && <Col xs={12} md={sidebarSize}>
          <div className="clear-both" style={{ maxWdith: "100%", maxHeight: '90vh', overflow: 'auto' }}>
            {gState.showNews && <NewsView key="news" header="News" kind="news" limit={10} doSearch={onSearch} />}

            {gState.showNotes && <NewsView key="notes" header="Notes" kind="notes" limit={10} doSearch={onSearch} />}
          </div>
        </Col>}
      </Row>
    </Container>
  )
}

export default withAuthenticator(WatchlistsPage)
