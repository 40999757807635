import React from 'react'
import { Button } from 'react-bootstrap'
import { MyPopover, LinkButton } from '.'

export const ConfirmButton = ({ onClick, text, confirmText, icon, iconEnd, size = 'sm', disabled, header = 'Are you sure?', variant, msg ='', className = 'me-2', onStart }) => {
  return (
    <MyPopover header={header} onStart={onStart} button={<Button variant={variant} size={size} disabled={disabled} className={className}>{icon} {text} {iconEnd}</Button>}>
      {!!msg && <div className="truncate mb-2">{msg}</div>}
      <LinkButton variant="danger" size={size} onClick={onClick} text={<>{icon} {confirmText || text} now {iconEnd}</>} />
    </MyPopover>
  )
}
