import { useState, useEffect } from 'react'
import { Loading, MyPopover, LinkButton, ALink, Checkbox, NoData, ThSort, DateTime, ConfirmButton, QuoteRange, AutoRefresh, Ticker } from '../widgets'
import { FaTimes, FaEllipsisH, FaEllipsisV, FaTrash, FaCaretDown, FaEdit } from 'react-icons/fa'
import { Table, Row, Col } from 'react-bootstrap'
import { apiGetList, apiPost, apiDelete } from '../../libs/apiLib'
import { cloneDeep, orderBy, uniqBy } from 'lodash'
import { processOrders } from '../../libs/dataLib'
import { toCurrency, toPercent, toNum, toNumber, getPositionColor, getPriceColor, getVolumeColor, trimTo } from '../../libs/utilLib'
import { OrdersChart } from '.'
import { orderColumns } from '../../libs/consts'
import { toast } from 'react-toastify'
import { Transactions } from '../transaction'
import { AddSymbolToWatchlist } from '../watchlist'
import { differenceInDays } from 'date-fns'
// import { Link } from 'react-router-dom'
// import { FaChevronUp, FaChevronDown } from 'react-icons/fa'

export const Orders = ({ account, onClose, pQuote }) => {
    const prefix = 'Orders'
    const [state, setState] = useState({
        visible: JSON.parse(localStorage.getItem(`${prefix}_visible_${account._id}`) || true),
        // height: JSON.parse(localStorage.getItem(`${prefix}_height_${account._id}`) || 800),
        // pinResize: JSON.parse(localStorage.getItem(`${prefix}_pinResize`) || true),
        working: false,
        columns: JSON.parse(localStorage.getItem(`${prefix}_columns`)) || orderColumns,
        hideEmpty: JSON.parse(localStorage.getItem(`${prefix}_hideEmpty`) || true),
        showFavor: JSON.parse(localStorage.getItem(`${prefix}_showFavor`)),
        sortOrderType: JSON.parse(localStorage.getItem(`${prefix}_sortOrderType`) || true),
        pinRefresh: JSON.parse(localStorage.getItem(`${prefix}_pinRefresh`) || true),
        pinCharts: JSON.parse(localStorage.getItem(`${prefix}_pinCharts`)),
        showTransactions: JSON.parse(localStorage.getItem(`${prefix}_showTransactions`)),
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'orderNumber',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'desc',
        colorExpiring: JSON.parse(localStorage.getItem(`${prefix}_colorExpiring_${account._id}`) || true),
        items: account.orders,
        types: null,
        typesText: JSON.parse(localStorage.getItem(`${prefix}_${account.mask}_typesText`)),
    })

    useEffect(() => {
        const tmp0 = state.typesText?.split(/, /).filter(x => x)
        setState(state => ({ ...state, items: account.orders, types: orderBy(uniqBy(account.orders.map(x => ({type: x.orderType, checked: !tmp0?.length || tmp0.find(y => y === x.orderType)})), 'type'), 'type') }))
        // fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    const fetchData = async () => {
        setState({ ...state, working: true })
        const { items } = await apiGetList({ model: 'order', filter: { mask: account.mask }, populates: [{ path: 'company', select: { dailyQuotes: 0, yearlyQuotes: 0 } }] })
        account.orders = items
        let types = uniqBy(items.map(x => ({type: x.orderType, checked: true})), 'type')
        console.log(types)
        setState({...state, items, types, working: false})
        // let orders = processOrders(cloneDeep(items), account.cash, state.useAfterMarket)
        // account.orders = orders
        // setState({ ...state, items: orders, working: false })
    }

    const refreshQuotes = async (range = '1d', source='yfinance2') => {
        setState({ ...state, working: true })
        let symbols = [...new Set(state.items.map(x => x.symbol))]
        let { updatedCount } = await apiPost('fetch/quotes', { symbols: symbols.join(','), range, source })
        if (updatedCount) {
            toast(`${updatedCount} out of ${symbols.length} quotes refreshed.`)
            fetchData()
        } else {
            toast(`No quotes refreshed.`)
            setState({ ...state, working: false })
        }
    }

    const onDelete = async (item) => {
        let result = await apiDelete('order', item._id)
        console.log(result)
        fetchData()
    }

    // const onResize = (size) => {
    //     let { height } = state
    //     height = Math.max(height + size, 0)
    //     localStorage.setItem(`${prefix}_height_${account._id}`, JSON.stringify(height))
    //     setState({ ...state, height })
    // }

    const onToggleByAccount = (name) => {
        localStorage.setItem(`${prefix}_${name}_${account._id}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    const onShow = (i) => {
        let { columns } = state
        columns[i].visible = !columns[i].visible
        localStorage.setItem(`${prefix}_columns`, JSON.stringify(columns))
        setState({ ...state, columns })
    }

    const onReset = () => {
        localStorage.removeItem(`${prefix}_columns`)
        setState({ ...state, columns: orderColumns })
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'desc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const onToggleFilter = (value, parent) => {
        if (value === 'all') state[parent].forEach(x => x.checked = true)
        else if (value === 'none') state[parent].forEach(x => x.checked = false)
        else value.checked = !value.checked
        const values = state[parent].find(x => !x.checked) ? state[parent].filter(x => x.checked).map(x => x.type).join(', ') : ''
        if (values) localStorage.setItem(`${prefix}_${account.mask}_${parent}Text`, JSON.stringify(values))
        else localStorage.removeItem(`${prefix}_${account.mask}_${parent}Text`)
        setState({ ...state, [parent]: state[parent], [`${parent}Text`]: values })
    }

    const showTrans = (symbol) => {
        setState({ ...state, showTransactions: true, symbol })
    }

    const canShow = (id) => {
        let isSpecial = 'dayRange,yearRange'.includes(id)
        if (!isSpecial && state.hideEmpty && !items.find(x => x[id])) return false
        return state.columns.find(x => x.id === id)?.visible
    }

    const getBgColor = (pct) => {
        if (Math.abs(pct) > 0.15) return '#80ff00'
        else if (Math.abs(pct) > 0.10) return '#00ffff'
        else if (Math.abs(pct) > 0.05) return '#ffff00'
        return ''
    }

    const getBgColor2 = (pct) => {
        if (Math.abs(pct) > 0.8) return '#80ff00'
        else if (Math.abs(pct) > 0.4) return '#00ffff'
        else if (Math.abs(pct) > 0.2) return '#ffff00'
        return ''
    }

    const getBgColor3 = (pct) => {
        if (pct > 0.15) return '#80ff00'
        else if (pct > 0.10) return '#00ffff'
        else if (pct > 0.05) return '#ffff00'
        else if (pct < -0.15) return '#ff4500'
        else if (pct < -0.1) return '#f08080'
        else if (pct < -0.05) return '#ffa07a'
        return ''
    }

    const getBgColor4 = (pct) => {
        if (pct > 1) return '#f08080'
        else if (pct > 0.5) return '#ffff00'
        return ''
    }
    
    const getBgColor5 = (pct) => {
        if (pct >= 1) return '#ff4500'
        else if (pct >= 0.75) return '#f08080'
        else if (pct >= 0.5) return '#ffff00'
        return ''
    }
    
    const getDateColor = (dt) => {
        if (!dt) return
        if (typeof (dt) == 'string') dt = new Date(dt)
        let days = differenceInDays(dt, new Date())
        if (days < 0) return
        if (days <= 7) return '#ffff00'
        else if (days <= 14) return '#00ffff'
        else if (days <= 30) return '#80ff00'
        return ''
    }

    const getOrderDateColor = (dt) => {
        if (!dt || !state.colorExpiring) return
        if (typeof (dt) == 'string') dt = new Date(dt)
        let days = differenceInDays(dt, new Date())
        if (days <= -60) return 'red'
        else if (days <= -55) return '#ffff00'
        return ''
    }

    const getOrderColor = (order) => {
        let { diffPercent, orderType, orderPrice, dayLow, dayHigh } = order
        if (!orderPrice) return ''
        if (orderType === 'Buy' && orderPrice > dayLow) return 'red'
        if (orderType === 'Sell' && orderPrice < dayHigh) return '#ff1493'  // deep pink
        if (diffPercent <= 0) return 'red'
        if (diffPercent < 0.01) return '#ffff00'
        else if (diffPercent < 0.05) return '#00ffff'
        else if (diffPercent < 0.1) return '#80ff00'
    }
   
    // const onYahooCharts = () => {
    //     [...new Set(account.orders.map(x => x.yahooSymbol || x.symbol))].forEach(x => window.open(`https://finance.yahoo.com/chart/${x}`))
    // }

    const dropdown = () => <>
        {/* <hr className="my-1" />
        <LinkButton text="Refresh yearly quotes" onClick={e => refreshYearly()} className="ps-0" /> */}
        {/* <hr className="my-2" /> */}
        {/* <Pin pinned={state.pinResize} onClick={e => onToggle('pinResize')} className="my-2"> */}
        {/* <div>
            Window height:
            <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
            <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
        </div> */}
        {/* </Pin> */}
        <hr className="my-2" />
        <div><Checkbox label="Color expiring orders" checked={state.colorExpiring} onCheck={e => onToggleByAccount('colorExpiring')} /></div>
        <div><Checkbox label="Distribution charts" checked={state.showCharts} onCheck={e => onToggle('showCharts')} /></div>
        {/* <hr className="my-1" />
        <div><LinkButton onClick={onYahooCharts} text="Yahoo charts for all tickers" className="ps-0" /></div> */}
        <hr className="my-2" />
        {onClose && <LinkButton icon={<FaTimes />} text="Close" onClick={onClose} className="pe-0 float-end" />}
        <LinkButton text={state.visible ? 'Hide orders' : 'Show orders'} onClick={e=>onToggle('visible')} className="ps-0" />
    </>


    if (state.working && !state.items) return <Loading />
    if (!state.items?.length) return <NoData text="No orders to show" />
    let items = processOrders(cloneDeep(state.items), account.cash, pQuote)
    const totalBuys = items.filter(x => x.orderType === 'Buy').reduce((t, x) => t += x.amount, 0)
    const totalSells = items.filter(x => x.orderType === 'Sell').reduce((t, x) => t += x.amount, 0)
    const cashLeft = items.filter(x => x.orderType === 'Buy')[0]?.cashLeft || account.cash
    items = items?.filter(x => !state.types?.length || state.types.find(y => y.checked && y.type === x.orderType))
    if (state.sortBy && state.sortDir) items = state.sortOrderType ? orderBy(items, ['orderType', state.sortBy], ['asc', state.sortDir]) : orderBy(items, [state.sortBy], [state.sortDir])
    const typeText = state.types?.length > 1 && state.types.filter(x => x.checked).length === 1 && state.types.filter(x => x.checked)[0].type 
    const getNameUrl = (symbol) => `https://finance.yahoo.com/quote/${symbol}`
    const getPriceUrl = (symbol) => `https://www.stockscores.com/charts/charts/?ticker=${symbol}`
    // console.log(items)
    return (
        <div className="mt-2">
            <div>
                <div className="float-end">
                    {/* {canShow('pPrice') && <Checkbox type="switch" label="Use after market quote" checked={state.useAfterMarket} onCheck={e => onToggle('useAfterMarket')} />} */}
                    <AutoRefresh key="holdings" id="holdings" working={state.working} variant="outline-secondary" doRefresh={e => refreshQuotes()} dropdown={dropdown()} className="ms-2" />
                </div>
                <h6 className="d-inline-block me-2 black">Orders [{account.name}]</h6>
                {items?.length > 0 && <MyPopover button={<><FaCaretDown className="p-cursor link"/> {typeText && <span className='text-muted f-sm'>[{typeText} only]</span>}</>} style={{ minWidth: 220 }}>
                    <div>
                        <Checkbox label="Sorted first" checked={state.sortOrderType} onCheck={e => onToggle('sortOrderType')} className="float-end"/>
                        <b>Order type:</b>
                    </div>
                    <hr className="my-1 clear-both" />
                    <Row>
                        {state.types?.map((x, i) => <Col key={i} xs={6}>
                            <Checkbox label={x.type} checked={!!x.checked} onCheck={e => onToggleFilter(x, 'types')} />
                        </Col>)}
                    </Row>
                    {(!!totalBuys || !!totalSells) && <>
                        <hr className="my-1 clear-both" />
                        {!!totalBuys && <>
                            <div className="float-end">{toCurrency(totalBuys)}</div>
                            <div>Total buys: </div>
                        </>}
                        {!!totalSells && <>
                            <div className="float-end">{toCurrency(totalSells)}</div>
                            <div>Total sells: </div>
                        </>}
                    </>}
                    {!!cashLeft && <>
                        <hr className="my-1 clear-both" />
                        <div className="float-end">{toCurrency(cashLeft)}</div>
                        <div>Cash left: </div>
                    </>}
                </MyPopover>}
                {/* <div className="ms-2 d-inline-block f-sm" style={{verticalAlign: 'top'}}>
                    <Link onClick={e => onToggleByAccount('visible')}>{state.visible ? <>Hide</> : <>Show</>}</Link>
                    {state.visible && state.pinResize && <>
                        <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
                        <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
                    </>}
                </div> */}
            </div>
            {state.visible && <div className="nowrap clear-both my-2" style={{ borderLeft: '1px solid black' }}>
                <Table size="sm" hover className="nowrap f-sm mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            {state.columns.filter(x => canShow(x.id)).map((x, i) => <ThSort key={i} id={x.id} text={x.name} sortBy={state.sortBy} sortDir={state.sortDir} onSort={onSort} className={x.className} />)}
                            <th className="text-end">
                                <MyPopover button={<FaEllipsisH className="grey mt-n1" />} className="ms-2 mt-n1">
                                    {/* <div><LinkButton icon={<FaCopy />} text="Copy table" onClick={onCopy} className="ps-0" /></div> */}
                                    {/* <hr className="my-1" /> */}
                                    <div><Checkbox label="Hide empty columns" checked={state.hideEmpty} onCheck={e => onToggle('hideEmpty')} /></div>
                                    <div><Checkbox label="Show favor icon" checked={state.showFavor} onCheck={e => onToggle('showFavor')} /></div>
                                    <hr className="my-1" />
                                    <div className="float-end"><LinkButton text="Reset to default" onClick={onReset} className="p-0" /></div>
                                    <div className="bold">Show columns</div>
                                    <Row className="clear-both mt-2" onClick={onStop}>
                                        {state.columns?.map((x, i) => <Col key={i} xs={6}>
                                            <Checkbox label={x.name} checked={x.visible} onCheck={e => onShow(i)} />
                                        </Col>)}
                                    </Row>
                                </MyPopover>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((x, i) => <tr key={i} className={state.sortOrderType && i < items.length-1 && x.orderType !== items[i+1].orderType ? 'separator' : ''}>
                            <td><LinkButton text={i + 1} to={`/chart/${x.symbol}`} target="_blank" className="p-0" /></td>
                            {canShow('date') && <td><span style={{backgroundColor: getOrderDateColor(x.date)}}>{x.date}</span></td>}
                            {canShow('orderNumber') && <td>{x.orderNumber}</td>}
                            {canShow('orderType') && <td>{x.orderType}</td>}
                            {canShow('symbol') && <td title={x.name}>
                                <Ticker symbol={x.symbol} yahooSymbol={x.yahooSymbol} showFavor={state.showFavor}/>
                                {/* <div className="float-end">
                                    <MyPopover button={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" title="Watching" /> : gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" title="Watch for buy" /> : gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" title="Watch for sell" /> : <FaRegHeart title="Favorite?" />}>
                                        <div><LinkButton icon={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" /> : <FaRegHeart />} text="Watching" onClick={e => gState.onFavor(x.symbol, 'watch')} /></div>
                                        <div><LinkButton icon={gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" /> : <FaRegThumbsUp />} text="Watch for buy" onClick={e => gState.onFavor(x.symbol, 'buy')} /></div>
                                        <div><LinkButton icon={gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" /> : <FaRegThumbsDown />} text="Watch for sell" onClick={e => gState.onFavor(x.symbol, 'sell')} /></div>
                                    </MyPopover>
                                </div>
                                <ALink href={`https://finance.yahoo.com/chart/${x.symbol}`} text={x.symbol} /> */}
                            </td>}
                            {canShow('name') && <td title={x.name} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {x.tags && <div className="float-end f-xs">{x.tags?.map((y, j) => <span key={j} className="px-1 grey me-1" style={{ border: '1px solid #ccc', borderRadius: 5, backgroundColor: y.startsWith('DJ') ? 'azure' : y.startsWith('NQ') ? 'seashell' : y.startsWith('DK') ? 'orange' : y.startsWith('DA') ? 'pink' : y.startsWith('DV') ? 'cyan' : '' }}>{y}</span>)}</div>}
                                <ALink href={getNameUrl(x.yahooSymbol || x.symbol)} text={x.name}>
                                    <span dangerouslySetInnerHTML={{ __html: trimTo(x.name||x.symbol, 80) }} title={x.name}></span>
                                </ALink>              
                            </td>}
                            {canShow('quantity') && <td className="text-end" style={{ color: x.quantity < 0 ? 'red' : '' }}>{toNum(x.quantity, 2, true)}</td>}
                            {canShow('orderPrice') && <td className="text-end bold">
                                {/* {toCurrency(x.orderPrice)} */}
                                <ALink href={getPriceUrl(x.yahooSymbol || x.symbol)}>
                                    <span style={{color: 'black'}} dangerouslySetInnerHTML={{ __html: toNum(x.orderPrice) }} ></span>
                                </ALink>
                            </td>}
                            {canShow('amount') && <td className="text-end">{toNum(x.amount)}</td>}
                            {canShow('diffAmount') && <td className="text-end">{toNum(x.diffAmount) || '$0'}</td>}
                            {canShow('diffPercent') && <td className="text-end"><div style={{ backgroundColor: getOrderColor(x) }}>{toPercent(x.diffPercent, 2) || '0%'}</div></td>}
                            {canShow('status') && <td>{x.status}</td>}
                            {/* {canShow('lastTraded') && <td><DateTime date={x.lastTraded} type="absolute" fmt="MM/dd HH:mm" /></td>} */}
                            {canShow('price') && <td className="text-end bold" title={`Pos: ${toPercent(x.position, 2) || '0%'} Up: ${toPercent(x.upPercent, 2) || '0%'} Down: ${toPercent(x.downPercent, 2)}`}>
                                {/* <span style={{backgroundColor: getPriceColor(x.position)}}>${toNum(x.price * (x.factor || 1), 4, true)}</span> */}
                                {/* <a href={getPriceUrl(x.yahooSymbol || x.symbol)} target="_blank" rel="noopener noreferrer">
                                    <span style={{color: 'black', backgroundColor: getPriceColor(x.position)}} dangerouslySetInnerHTML={{ __html: toNum(x.price * (x.factor || 1), 4, true) }} ></span>
                                </a> */}
                                <ALink href={getPriceUrl(x.yahooSymbol || x.symbol)}>
                                    <span style={{color: 'black', backgroundColor: getPriceColor(x.position)}} dangerouslySetInnerHTML={{ __html: toNum(x.price * (x.factor || 1), 4, true) }} ></span>
                                </ALink>
                            </td>}
                            {/* {canShow('price') && <td className="text-end bold" title={toPercent(x.position, 2)}><span style={{backgroundColor: getPositionColor(x.position)}}>${toNum(x.price * (x.factor || 1), 4, true)}</span></td>} */}
                            {/* {canShow('price') && <td className="text-end bold">${toNum(x.price, 4, true)}</td>} */}
                            {canShow('change') && <td className="text-end" style={{ color: x.change < 0 ? 'red' : 'blue' }}>{toNum(x.change)}</td>}
                            {/* {canShow('changePercent') && <td className="text-end" style={{ color: x.change < 0 ? 'red' : 'blue' }}>{toPercent(x.changePercent, 2)}</td>} */}
                            {canShow('changePercent') && <td><div className={`${x.change > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(x.changePercent / 100) }}>{!!x.changePercent && toPercent(x.changePercent / 100, 2)}</div></td>}
                            {canShow('lastTraded') && <td className="text-center"><div style={{backgroundColor: differenceInDays(x.lastTraded, new Date()) < -5 ? 'red':''}}><DateTime date={x.lastTraded} kind="absolute" compact /></div></td>}
                            {/* {canShow('lastTraded') && <td className="text-center"><DateTime date={x.lastTraded} kind="absolute" compact /></td>} */}
                            {canShow('dayRange') && <td className="text-center"><QuoteRange low={x.dayLow} high={x.dayHigh} close={x.price} open={x.open} /></td>}
                            {canShow('volume') && <td className="text-end" title={`3M avg: ${toNumber((x.avgVolume||0)/1000, 1, 1)}, 10D avg: ${toNumber((x.avgVolume10D||0)/1000, 1, 1)}`}><div className={x.avgVolume10D > 2*x.avgVolume ? 'bold' : ''} style={{backgroundColor: getVolumeColor(x.volume, x.avgVolume)}}>{x.volume ? toNumber(x.volume / 1000, 1, 1) : ''}</div></td>}
                            {canShow('avgVolume') && <td className="text-end">{x.avgVolume ? toNumber(x.avgVolume / 1000, 1, 1) : ''}</td>}
                            {canShow('yearRange') && <td className="text-center"><QuoteRange low={Math.min(x.yearLow, x.dayLow||x.yearLow)} high={Math.max(x.yearHigh, x.dayHigh)} close={x.price} open={x.open} kind="year" /></td>}
                            {canShow('position') && <td className="text-end"><span style={{ backgroundColor: getPositionColor(x.position) }}>{toPercent(x.position, 2)}</span></td>}
                            {canShow('upPercent') && <td className="text-end">{toPercent(x.upPercent, 2)}</td>}
                            {canShow('downPercent') && <td className="text-end">{toPercent(x.downPercent, 2)}</td>}
                            {canShow('mktCap') && <td className="text-end">{!!x.mktCap ? toNum(x.mktCap / 1000000, 0, 0) : ''}</td>}
                            {canShow('pe') && <td className="text-end"><span style={{backgroundColor: x.pe >= 10 && x.pe <= 20 ? '#adff2f' : '' }}>{toNum(x.pe)}</span></td>}
                            {canShow('eps') && <td className={`text-end ${x.eps < 0 ? 'red':''}`}><span style={{backgroundColor: x.eps >= 25 ? '#adff2f' : '' }}>{toNum(x.eps)}</span></td>}
                            {canShow('targetPrice') && <td className={`text-end ${x.targetPrice >= x.price ? 'blue' : 'red'}`}><span style={{ backgroundColor: getBgColor2((x.targetPrice - x.price) / x.price) }}>{toNum(x.targetPrice)}</span></td>}
                            {canShow('earnDate') && <td><span style={{ color: `${new Date(x.earnDate) < new Date() ? '#aaaaaa' : ''}`, backgroundColor: getDateColor(x.earnDate) }}><DateTime date={x.earnDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
                            {canShow('divDate') && <td><span style={{ color: `${new Date(x.divDate) < new Date() ? '#aaaaaa' : ''}`, backgroundColor: getDateColor(x.divDate) }}><DateTime date={x.divDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
                            {canShow('divValue') && <td className="text-end">{!!x.divValue && toNum(x.divValue)}</td>}
                            {canShow('divYield') && <td className={`text-end ${x.divFreq === 'M' || x.divFreq === 'W' ? 'bold' : ''}`}><span title={`DivFreq: ${x.divFreq||'-'}, DivSince: ${x.divSince||'-'}, ExpenseRatio: ${x.expenseRatio||'-'}, PayoutRatio: ${x.payoutRatio||'-'}`} style={{ backgroundColor: getBgColor(x.divYield / 100) }}>{!!x.divYield && toPercent(x.divYield / 100, 2)}</span></td>}
                            {canShow('divFreq') && <td>{x.divFreq}</td>}
                            {canShow('divSince') && <td>{x.divSince}</td>}
                            {canShow('expenseRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor5(x.expenseRatio) }}>{!!x.expenseRatio && toPercent(x.expenseRatio/100, 2)}</span></td>}
                            {canShow('payoutRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor4(x.payoutRatio / 100) }}>{!!x.payoutRatio && toPercent(x.payoutRatio/100, 2)}</span></td>}
                            {canShow('priceToSales') && <td className="text-end">{x.priceToSales}</td>}
                            {canShow('priceToBook') && <td className="text-end">{x.priceToBook}</td>}
                            {canShow('evToRevenue') && <td className="text-end">{x.evToRevenue}</td>}
                            {canShow('evToEbitda') && <td className="text-end">{x.evToEbitda}</td>}
                            {canShow('ytdReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor3(x.ytdReturn / 100) }}><a href={`https://finance.yahoo.com/quote/${x.yahooSymbol||x.symbol}/performance/`} target="_blank" rel="noopener noreferrer">{!!x.ytdReturn && toPercent(x.ytdReturn/100, 2)}</a></span></td>}
                            {canShow('t3mReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor3(x.t3mReturn / 100) }}>{!!x.t3mReturn && toPercent(x.t3mReturn/100, 2)}</span></td>}
                            {canShow('sector') && <td title={x.sector} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.sector}</td>}
                            {canShow('industry') && <td title={`Established: ${x.year}, HQ: ${x.hq}, Country: ${x.country}, Sector: ${x.sector}, Industry: ${x.industry}`} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.industry}</td>}
                            {canShow('year') && <td><span title={`HQ: ${x.hq||'-'}, Country: ${x.country||'-'}, Sector: ${x.sector||'-'}, Industry: ${x.industry||'-'}`}>{x.year||''}</span></td>}
                            {canShow('hq') && <td title={x.hq} style={{maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.hq}</td>}
                            {canShow('action') && <td title={x.action} style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.action}</td>}
                            {canShow('remarks') && <td title={x.remarks} style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.remarks}</td>}
                            {/* {canShow('remarks') && <td title={x.remarks} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}><DivHtml html={x.remarks} /></td>} */}
                            {/* {canShow('bidPrice') && <td className="text-end">{toCurrency(x.bidPrice)}</td>}
                            {canShow('askPrice') && <td className="text-end">{toCurrency(x.askPrice)}</td>} */}
                            {/* {canShow('lastPrice') && <td className="text-end">{toCurrency(x.lastPrice)}</td>} */}
                            {/* {canShow('cashLeft') && <td className="text-end">{toCurrency(x.cashLeft)}</td>} */}
                            {canShow('pPrice') && <td className="text-end bold">{x.pPrice && <span style={{backgroundColor: (x.orderType === 'Buy' && x.pPrice <= x.orderPrice) || (x.orderType === 'Sell' && x.pPrice >= x.orderPrice) ? 'red':''}}>{toNum(x.pPrice * (x.factor || 1), 4, true)}</span>}</td>}
                            {canShow('pChange') && <td className="text-end" style={{ color: x.pChange < 0 ? 'red' : 'blue' }}>{toNum(x.pChange)}</td>}
                            {canShow('pChangePercent') && <td><div className={`${x.pChange > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(x.pChangePercent / 100) }}>{!!x.pChangePercent && toPercent(x.pChangePercent / 100, 2)}</div></td>}
                            {canShow('pLastTraded') && <td className="text-center"><DateTime date={x.pLastTraded} kind="absolute" compact /></td>}
                            {canShow('updatedAt') && <td style={{ maxWidth: 50 }}><DateTime date={x.updatedAt} fmt="yyyy-MM-dd" /></td>}
                            <td className="text-end nowrap">
                                <MyPopover button={<FaEllipsisV className="ms-2 grey" />}>
                                    <div><ConfirmButton variant="link" icon={<FaTrash className="red" />} text={`Delete order for ${x.symbol}`} onClick={e => onDelete(x)} className="ps-0" /></div>
                                    <hr className="my-2" />
                                    <div><LinkButton to={`/company/${x.symbol}/edit`} icon={<FaEdit />} text="Edit ticker" className="ps-0" /></div>
                                    <AddSymbolToWatchlist symbol={x.symbol} />
                                    <hr className="my-2" />
                                    <LinkButton text={`View transactions for ${x.symbol}`} onClick={e => showTrans(x.symbol)} className="ps-0 pt-0" />
                                </MyPopover>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </div>}

            {state.showCharts && <OrdersChart items={items} onClose={e => onToggle('showCharts')} />}
            {state.showTransactions && <Transactions account={account} symbol={state.symbol} onClose={e => onToggle('showTransactions')} />}
        </div>
    )
}